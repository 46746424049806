import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Header from './components/Header';
import Lesson from './components/Lesson';
import './App.css';

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div className="App">
      <Header />
      {isAuthenticated ? (
        <Lesson lessonId="lesson1" />
      ) : (
        <div className="welcome-message">
          <h2>Welcome to Jennifer AI</h2>
          <p>Please log in to access your personalized learning experience.</p>
        </div>
      )}
    </div>
  );
}

export default App;