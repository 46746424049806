import React, { useState, useEffect } from 'react';

interface ExplanationProps {
  trigger: string | null;
}

const Explanation: React.FC<ExplanationProps> = ({ trigger }) => {
  const [content, setContent] = useState<string | null>(null);
  const [asset, setAsset] = useState<string | null>(null);

  useEffect(() => {
    console.log('Explanation trigger received:', trigger);
    if (trigger) {
      setContent(trigger);
      
      // Check if the trigger is an asset reference
      const assetMatch = trigger.match(/\[(images\/.*?)\]/);
      if (assetMatch) {
        const assetPath = assetMatch[1];
        const bucketUrl = process.env.REACT_APP_CONTENT_BUCKET_WEBSITE_URL || 'https://jennifer-ai-avatar-content.s3.amazonaws.com';
        const fullAssetUrl = `${bucketUrl}/${assetPath}`;
        console.log('Setting asset URL:', fullAssetUrl);
        setAsset(fullAssetUrl);
      } else {
        console.log('No asset reference found in trigger');
        setAsset(null);
      }
    } else {
      console.log('No trigger received, clearing content and asset');
      setContent(null);
      setAsset(null);
    }
  }, [trigger]);

  return (
    <div className="explanation">
      <h3>Explanation</h3>
      {content ? (
        <>
          <p>{content}</p>
          {asset && (
            <img 
              src={asset} 
              alt="Explanation visual" 
              style={{ maxWidth: '100%' }} 
              onError={(e) => {
                console.error('Error loading image:', e);
                e.currentTarget.style.display = 'none';
              }}
            />
          )}
        </>
      ) : (
        <p>No explanation available</p>
      )}
    </div>
  );
};

export default Explanation;