import React from 'react';
import ReactMarkdown from 'react-markdown';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

interface RichTextRendererProps {
  content: string;
}

const RichTextRenderer: React.FC<RichTextRendererProps> = ({ content }) => {
  // Replace content triggers with a placeholder
  const processedContent = content.replace(/__content__([^.]+\.\w+)/g, '‼CONTENT_TRIGGER‼');

  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        code({ node, inline, className, children, ...props }: any) {
          const match = /language-(\w+)/.exec(className || '');
          if (!inline && match && match[1] === 'math') {
            return <BlockMath math={String(children).replace(/\n/g, ' ')} />;
          }
          if (inline) {
            return <InlineMath math={String(children)} />;
          }
          return <code className={className} {...props}>{children}</code>;
        },
      }}
    >
      {processedContent}
    </ReactMarkdown>
  );
};

export default RichTextRenderer;