import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';

const NewChatButton: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const handleNewChat = async () => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    try {
      const accessToken = await getAccessTokenSilently();
      
      console.log('API Endpoint:', process.env.REACT_APP_API_ENDPOINT);
      console.log('User ID:', user.sub);
      console.log('Access Token:', accessToken);

      // Save current thread
      console.log('Saving current thread...');
      const saveResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/save-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ userId: user.sub })
      });

      if (!saveResponse.ok) {
        const errorText = await saveResponse.text();
        throw new Error(`HTTP error! status: ${saveResponse.status}, message: ${errorText}`);
      }

      const saveResult = await saveResponse.json();
      console.log('Thread saved:', saveResult);

      // Create new thread
      console.log('Creating new thread...');
      const createResponse = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/create-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ userId: user.sub })
      });

      if (!createResponse.ok) {
        const errorText = await createResponse.text();
        throw new Error(`HTTP error! status: ${createResponse.status}, message: ${errorText}`);
      }

      const createResult = await createResponse.json();
      console.log('New thread created:', createResult);

      // Refresh the client (you might want to use React state or context instead)
      window.location.reload();
    } catch (error) {
      console.error('Error creating new chat:', error);
      if (error instanceof Error) {
        console.error('Error name:', error.name);
        console.error('Error message:', error.message);
      } else {
        console.error('Unknown error:', error);
      }
    }
  };

  return (
    <Button onClick={handleNewChat} startIcon={<ChatIcon />}>
      New Chat
    </Button>
  );
};

export default NewChatButton;