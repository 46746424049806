import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import NewChatButton from './NewChatButton';
import './Header.css';

const Header: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/logo192.png`} className="app-logo" alt="logo" />
          <h1>Jennifer AI</h1>
        </div>
        <div className="auth-button">
          {isAuthenticated && <NewChatButton />}
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </div>
      </div>
    </header>
  );
};

export default Header;