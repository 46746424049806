import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import RichTextRenderer from './RichTextRenderer';
import AnimatedCircle from './AnimatedCircle';
import Explanation from './Explanation';

interface ChatAreaProps {
  lessonId: string;
  onExplanationTrigger: (trigger: string) => void;
}

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

const ChatArea: React.FC<ChatAreaProps> = ({ lessonId, onExplanationTrigger }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const [isConnected, setIsConnected] = useState(false);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [messageStatus, setMessageStatus] = useState<'waiting' | 'receiving' | 'complete'>('complete');
  const [explanationTrigger, setExplanationTrigger] = useState<string | null>(null);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const handleWebSocketMessage = useCallback((event: MessageEvent) => {
    console.log('🟢 WebSocket message received:', event.data);
    try {
      const data = JSON.parse(event.data);
      console.log('🟢 Parsed WebSocket data:', data);

      if (data.type === 'error') {
        console.error('🔴 Server reported an error:', data.content);
        setMessages(prevMessages => [
          ...prevMessages, 
          { role: 'assistant', content: `Error: ${data.content}` }
        ]);
        setMessageStatus('complete');
        return;
      }

      if (data.type === 'message') {
        console.log('🟢 Received content message:', data.content);
        if (data.content === '[DONE]') {
          console.log('🟢 Stream completed');
          setMessageStatus('complete');
        } else {
          setMessageStatus('receiving');
          setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.role === 'assistant') {
              const updatedMessages = [...prevMessages];
              updatedMessages[updatedMessages.length - 1] = {
                ...lastMessage,
                content: lastMessage.content + data.content
              };
              return updatedMessages;
            } else {
              return [...prevMessages, { role: 'assistant', content: data.content }];
            }
          });

          const triggerMatch = data.content.match(/\[([^\]]+)\]/);
          if (triggerMatch) {
            console.log('🟢 Explanation trigger detected:', triggerMatch[1]);
            const triggerContent = triggerMatch[1];
            onExplanationTrigger(triggerContent);
            setExplanationTrigger(triggerContent);
          }                
        }
      } else {
        console.warn('🟡 Received WebSocket message with unexpected format:', data);
      }
    } catch (error) {
      console.error('🔴 Error parsing WebSocket message:', error);
      console.error('🔴 Raw message that caused the error:', event.data);
    }
  }, [onExplanationTrigger]);

  const initializeWebSocket = useCallback(async () => {
    if (!isAuthenticated || !user) {
      console.log('🟡 User not authenticated, delaying WebSocket initialization');
      return;
    }

    if (socket) {
      console.log('🟡 Closing existing WebSocket connection');
      socket.close();
    }

    // Get the access token
    const accessToken = await getAccessTokenSilently();

    console.log('🟠 Initializing WebSocket connection to:', process.env.REACT_APP_WEBSOCKET_API_ENDPOINT);
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_API_ENDPOINT!);
    
    ws.onopen = () => {
      console.log('🟢 WebSocket connected');
      setIsConnected(true);
      const initMessage = JSON.stringify({ action: 'initialize', userId: user.sub, accessToken });
      console.log('🟠 Sending initialization message:', initMessage);
      ws.send(initMessage);
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
    };
    
    ws.onclose = (event) => {
      console.log('🔴 WebSocket disconnected:', event);
      setIsConnected(false);
      reconnectTimeoutRef.current = setTimeout(initializeWebSocket, 3000);
    };
    
    ws.onerror = (error) => {
      console.error('🔴 WebSocket error:', error);
      setIsConnected(false);
    };
    
    ws.onmessage = handleWebSocketMessage;
    setSocket(ws);
  }, [isAuthenticated, user, getAccessTokenSilently, handleWebSocketMessage]);

  useEffect(() => {
    if (isAuthenticated && user) {
      initializeWebSocket();
    }

    return () => {
      console.log('🟠 Cleaning up WebSocket connection');
      if (socket) {
        socket.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [isAuthenticated, user, initializeWebSocket]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    if (!input.trim() || !isAuthenticated || !user || !socket || !isConnected) {
      console.log('🔴 Cannot send message: input, user, socket, or connection is missing');
      return;
    }

    console.log('🟠 Sending message:', input);
    const newUserMessage: Message = { role: 'user', content: input };
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInput('');
    setMessageStatus('waiting');

    try {
      const accessToken = await getAccessTokenSilently();
      const message = JSON.stringify({
        action: 'chat',
        lessonId,
        message: input,
        userId: user.sub, // Using Auth0 user ID
        accessToken
      });
      console.log('🟢 Sending WebSocket message:', message);
      socket.send(message);
      
      // Immediately add an empty assistant message with the AnimatedCircle
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'assistant', content: '' }
      ]);
    } catch (error) {
      console.error('🔴 Error sending message:', error);
      setMessages(prevMessages => [
        ...prevMessages, 
        { role: 'assistant', content: 'Error: Failed to send message' }
      ]);
      setMessageStatus('complete');
    }
  };

  return (
    <div className="chat-area-container" style={{ display: 'flex', width: '100%' }}>
      <div className="explanation-area" style={{ width: '30%', padding: '20px', borderRight: '1px solid #ccc' }}>
        <Explanation trigger={explanationTrigger} />
      </div>
      <div className="chat-area" style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
        <div className="connection-status">
          {isConnected ? '🟢 Connected' : '🔴 Disconnected'}
        </div>
        <div className="chat-box" ref={chatBoxRef} style={{ flexGrow: 1, overflowY: 'auto', padding: '20px' }}>
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.role}`}>
              <RichTextRenderer content={msg.content} />
              {msg.role === 'assistant' && index === messages.length - 1 && messageStatus !== 'complete' && (
                <AnimatedCircle status={messageStatus} />
              )}
            </div>
          ))}
        </div>
        <div className="input-area" style={{ padding: '20px' }}>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            style={{ flexGrow: 1, marginRight: '10px', padding: '5px' }}
          />
          <button onClick={handleSend} disabled={!isConnected} style={{ padding: '5px 10px' }}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ChatArea;