import React, { useEffect, useRef } from 'react';

interface AnimatedCircleProps {
  status: 'waiting' | 'receiving' | 'complete';
}

const AnimatedCircle: React.FC<AnimatedCircleProps> = ({ status }) => {
  const circleRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    if (!circleRef.current) return;

    const circle = circleRef.current;

    if (status === 'waiting') {
      circle.animate(
        [
          { r: 4 },
          { r: 2 },
          { r: 4 }
        ],
        {
          duration: 1000,
          iterations: Infinity
        }
      );
    } else if (status === 'receiving') {
      circle.animate(
        [
          { r: 4 }
        ],
        {
          duration: 0,
          fill: 'forwards'
        }
      );
    }
  }, [status]);

  if (status === 'complete') return null;

  return (
    <svg width="24" height="24" style={{ display: 'inline', verticalAlign: 'middle', marginLeft: '5px' }}>
      <circle ref={circleRef} cx="12" cy="12" r="4" fill="black" />
    </svg>
  );
};

export default AnimatedCircle;