import React from 'react';
import ChatArea from './ChatArea';

interface LessonProps {
  lessonId: string;
}

const Lesson: React.FC<LessonProps> = ({ lessonId }) => {
  const handleExplanationTrigger = (trigger: string) => {
    console.log('Explanation triggered:', trigger);
    // You can add additional logic here if needed
  };

  return (
    <div className="lesson">
      <ChatArea 
        lessonId={lessonId}
        onExplanationTrigger={handleExplanationTrigger}
      />
    </div>
  );
};

export default Lesson;